
#data_browse .ant-menu-item-selected {
    color: #1890ff !important;
    border-bottom: none !important;
    transition: none !important;
}

#root, #map {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

.data-info > p {
    margin-top: 10px;
    margin-bottom: 10px;
}

.ant-layout-header {
    height: 36px;
    line-height: 36px;
}

.ant-dropdown-trigger {
    font-weight: normal !important;
}

.ant-menu-sub.ant-menu-inline>.ant-menu-item, .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
    height: fit-content;
    line-height: normal;
    list-style-position: inside;
    list-style-type: disc;
    padding: 6px 10px 6px 0px;
}

.ant-menu-item, .ant-menu-submenu-title {
    cursor: pointer;
    display: block;
    margin: 0;
    padding: 0 20px;
    position: relative;
    transition: border-color .3s,background .3s,padding .3s cubic-bezier(.645,.045,.355,1);
    white-space: normal;
    height: fit-content;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: fit-content;
    line-height: normal;
    padding: 6px 10px 6px 0px;
}
