
.ant-checkbox-group {
    display: flex;
    flex-direction: column;
}

.ant-checkbox {
    font-size: 9pt;
    font-weight: normal;
}

.checkbox-green .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #008100;
    border-color: #008100;
}



