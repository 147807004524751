
.ant-menu-item-selected.ant-menu-overflow-item {
    color: #fff !important;
    /* border-color:rgba(0,0,0,0.9) !important ; */
    transition: none !important;
    height: 55 !important;
    background-color: rgba(0,0,0,0.3) !important;    
    padding-bottom: 6px !important;
    padding-top: 5px !important; 
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
    color: white !important;
    border-color: white !important;
    border-bottom: none !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
     border-bottom: 3 !important;
     border-color: rgba(0,0,0,0.9) !important ;
}

.ant-menu-overflow-item.ant-menu-item {
    height: 55 !important;
    color: rgba(255,255,255,0.6)
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
    transition: none !important;
    border-color: none !important;

}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--background-color, #0187FD);
    border-color: var(--border-color, #0187FD);
}

/* .ant-checkbox-checked .ant-checkbox-inner {
    background-color: red;
    border-color: red;
} */

/* 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover , .ant-menu-item-active  {
    border-bottom: none !important;
} */