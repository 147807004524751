.ant-table-tbody{
    padding: 2px 10px 2px 10px !important;
}

.ant-table-tbody > tr > td{
    padding: 2px 10px 2px 10px !important;
}

.ant-table-thead > tr > th{
    padding: 2px 10px 2px 10px !important;
    background-color: lightgrey  !important;
}
